import React from "react";
import { Trans, useTranslation } from "react-i18next";
import tw, { css } from "twin.macro";

import EquipmentOwnerSvg from "../../../static/images/equipment-owners.svg";
import ExpertSvg from "../../../static/images/experts.svg";
import MaterialOwnerSvg from "../../../static/images/material-owners.svg";
import { BookMeeting } from "../../components/BookMeeting";
import { Container } from "../../components/Container";
import { getTranslationList } from "../../utils/common";

const proposalLogos = css`
  svg {
    ${tw`fill-current text-turquoise w-32 h-32 mx-0 my-5`}
  }
`;
const proposalStyles = css`
  ${tw`mx-2 my-4 text-lg sm:text-xl`}
`;

const PitchSaveYourself: React.FC = ({ ...rest }) => {
  const { t } = useTranslation();

  const proposalList = getTranslationList(t, "index.proposal.list");

  return (
    <Container {...rest}>
      <section id="save-yourself">
        <article>
          <h2>{t("index.proposal.header")}</h2>
          <div css={proposalLogos} tw="flex justify-center mx-0">
            <MaterialOwnerSvg />
            <EquipmentOwnerSvg />
            <ExpertSvg />
          </div>
          {proposalList.map((item, i) => (
            <p css={proposalStyles} key={i}>
              <Trans i18nKey={t(item)} components={{ bold: <strong /> }} />
            </p>
          ))}
          <p tw="my-10 text-sm sm:text-base">{t("index.proposal.disclaimer")}</p>
          <BookMeeting />
        </article>
      </section>
    </Container>
  );
};

export default PitchSaveYourself;
